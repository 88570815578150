import React from 'react'
import { TicketResaleContainer } from 'tf-checkout-react'
import Head from '../../../components/head/index'
import Layout from '../../../components/layout/index'
import './style.scss'
const Ticket = () => {
  return (
    <>
      <Head title='Resale Page' />
      <Layout>
        <TicketResaleContainer
          defaultRedirection={false}
          onProcessTicketSuccess={async (data) => {
            if (data.data.attributes.has_add_on && !data.data.attributes.include_add_on) {
              window.location.href = `/billing/billing-info?event_id=${data.data.attributes.event_id}&resale=true`
            } else {
              window.localStorage.removeItem('add_ons');
              window.location.href = `/billing/billing-info?event_id=${data.data.attributes.event_id}&resale=true&include_add_on=true`
            }
          }}
        />
      </Layout>
    </>
  )
}
export default Ticket
